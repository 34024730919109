import React from "react";

class MenuItem extends React.Component {
    render() {
        return (
            <button
                style={{
                    margin: 0,
                    border: "none",
                    width: "200px",
                    flexGrow: 0,
                    flexShrink: 0,
                    backgroundColor: this.props.selected ? "lightblue" : "#eeeeee",
                    borderRadius: "0",
                    borderBottom: "1px dotted grey"
                }}
                onClick={this.props.onClick}
            >
                {this.props.children}
            </button>
        );
    }
}

export default MenuItem;
