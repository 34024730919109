import React from 'react';
import LocalDateTime from "./LocalDateTime";

class Calendar extends React.Component {
    getStartDay() {
        return this.props.startMonth;
    }

    getEndDay() {
        return this.props.endMonth.addDays(this.props.endMonth.getNumberOfDaysInMonth() - 1);
    }

    getNumberOfDays() {
        let endDay = this.getEndDay();
        let result = 0;
        for (let day = this.getStartDay(); day.isBefore(endDay); day = day.addMonths(1))
            result += day.getNumberOfDaysInMonth();
        return result;
    }

    getNumberOfWeeks() {
        return Math.ceil((this.getStartDay().getDayOfWeek() + this.getNumberOfDays() - 1) / 7);
    }

    renderHeaders() {
        return ([
                <div key="0"/>,
                <div key="KW" className="header smallHeader">KW</div>,
                <div key="mo" className="header">Mo</div>,
                <div key="di" className="header">Di</div>,
                <div key="mi" className="header">Mi</div>,
                <div key="do" className="header">Do</div>,
                <div key="fr" className="header">Fr</div>,
                <div key="sa" className="header">Sa</div>,
                <div key="so" className="header">So</div>
            ]
        );
    }

    renderWeeks() {
        let today = LocalDateTime.now();
        let startDay = this.getStartDay();
        let endDay = this.getEndDay();
        let result = [];
        for (let day = startDay.addDays(1 - startDay.getDayOfWeek()); day.isBefore(endDay); day = day.addDays(7)) {
            result.push(
                <div className="week" key={"KW" + day.toISOString()}>
                    {day.getWeekOfYear()}
                </div>
            );
            for (let weekDayIndex = 0; weekDayIndex < 7; ++weekDayIndex) {
                let weekDay = day.addDays(weekDayIndex);
                let classNames = "day ";
                if (weekDay.equalsDate(today))
                    classNames += "day-today ";
                if (weekDayIndex === 6 || weekDay.isHoliday())
                    classNames += "day-sunday ";
                if (weekDay.equalsDate(this.props.highlightedDate))
                    classNames += "day-highlighted ";
                result.push(
                    <div
                        key={weekDay.toISOString()}
                        className={classNames}
                        onClick={() => this.props.onDateClicked(weekDay)}
                    >
                        {weekDay.getDay()}
                    </div>
                );
            }
        }
        return result;
    }

    renderMonths() {
        let startDay = this.getStartDay();
        let endDay = this.getEndDay();
        let result = [];
        let startRow = startDay.getDayOfWeek() === 1 ? 1 : 2;
        for (let day = startDay; day.isBefore(endDay); day = day.addMonths(1)) {
            let numberOfWeeks = Math.ceil((day.getDayOfWeek() + day.getNumberOfDaysInMonth() - 1) / 7);
            let rows = numberOfWeeks * 2;
            if (day.getDayOfWeek() !== 1)
                --rows;
            if (day.addMonths(1).getDayOfWeek() !== 1)
                --rows;
            let endRow = startRow + rows;
            result.push(
                <div
                    key={day.getShortMonthName() + day.getYear()}
                    className="month"
                    style={{
                        gridColumn: "1",
                        gridRow: startRow + "/" + endRow
                    }}
                >
                    {day.getShortMonthName()}<br/>{day.getShortYear()}
                </div>
            );
            startRow = endRow;
        }
        return result;
    }

    render() {
        if (this.props.endMonth.isBefore(this.props.startMonth))
            return (
                <div>Fehler: negativer Zeitraum</div>
            )
        let numberOfWeeks = this.getNumberOfWeeks();


        let height = 35 * numberOfWeeks;


        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "365px",
                    maxHeight: "calc(100% - 142px)",
                    flexGrow: 1,
                    flexShrink: 1,
                    backgroundColor: "white"
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "45px 20px 35px 35px 35px 35px 35px 35px 35px",
                        gridTemplateRows: "35px",
                        gridGap: "5px",
                        height: "35px",
                        width: "365px",
                        flexGrow: "0",
                        flexShrink: "0"
                    }}
                >
                    {this.renderHeaders()}
                </div>
                <div
                    style={{
                        flexGrow: "1",
                        flexShrink: "1",
                        overflowY: "scroll",
                        overflowX: "hidden"
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "45px 20px 35px 35px 35px 35px 35px 35px 35px",
                            gridTemplateRows: "15px",
                            gridGap: "5px",
                            height: height + "px",
                            width: "365px"
                        }}
                    >
                        {this.renderMonths()}
                        {this.renderWeeks()}
                    </div>
                </div>
            </div>
        );
    }
}

export default Calendar;