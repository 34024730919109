import React from "react";
import ItemMenuDropdown from "./ItemMenuDropdown";
import Menu from "./Menu";

class MainMenu extends React.Component {
    render() {
        return (
            <Menu
                buttonContent={this.props.title}
                anchor="leftTop"
                offsetX={0}
                offsetY={0}
                disabled={this.props.disabled}
                onKeyDown={this.props.onKeyDown}
            >
                <ItemMenuDropdown>
                    {this.props.children}
                </ItemMenuDropdown>
            </Menu>
        );
    }
}

export default MainMenu;
