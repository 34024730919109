import React from "react";
import TriggeredPopup from "./TriggeredPopup";
import "./Select.css";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';


class Select extends React.Component {
    constructor(props) {
        super(props);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onKeyDown(event) {
        event.stopPropagation();
        event.preventDefault();
        switch (event.which) {
            case 38:
                if (this.props.selected.index > 0)
                    this.props.onChange(this.props.options[this.props.selected.index - 1]);
                break;
            case 40:
                if (this.props.selected.index < this.props.options.length - 1)
                    this.props.onChange(this.props.options[this.props.selected.index + 1]);
                break;
            default:
        }
    }

    render() {
        let getText = this.props.getText ? this.props.getText : text => text;
        let selectedText = this.props.selected ? getText(this.props.selected) : "";
        if (this.props.options.length <= 1)
            return (
                <button className={"select-trigger"}>
                    {selectedText}
                </button>
            );
        else
            return (
                <TriggeredPopup
                    trigger={
                        <button className={"select-trigger"}>
                            {selectedText}
                            <ArrowDropUpIcon/>
                        </button>}
                    popup={
                        <div className="select-popup">
                            {this.props.options.map(option => {
                                let text = getText(option);
                                return (
                                    <button contentEditable={false}
                                            className={text === selectedText ? "select-option select-option-selected" : "select-option"}
                                            key={text}
                                            onClick={() => this.props.onChange(option)}
                                    >
                                        {text}
                                    </button>)
                            })
                            }
                        </div>}
                    triggerAnchor="leftBottom"
                    popupAnchor="leftBottom"
                    offsetX={-2}
                    offsetY={2}
                    captureKeyboard
                    onKeyDown={this.onKeyDown}
                />
            );
    }
}

export default Select;