import React from "react";

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extended: false
        };
        this.onMouseDownAnywhere = this.onMouseDownAnywhere.bind(this);
        this.onResizeOrScroll = this.onResizeOrScroll.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        document.body.addEventListener('mousedown', this.onMouseDownAnywhere, true);
        window.addEventListener('resize', this.onResizeOrScroll, true);
        window.addEventListener('scroll', this.onResizeOrScroll, false);
    }

    componentWillUnmount() {
        document.body.removeEventListener('mousedown', this.onMouseDownAnywhere, true);
        window.removeEventListener('resize', this.onResizeOrScroll, true);
        window.removeEventListener('scroll', this.onResizeOrScroll, false);
    }

    onResizeOrScroll() {
        this.setState({extended: false});
    }

    onKeyDown(event) {
        this.setState({extended: true});
        this.props.onKeyDown(event);
    }

    onMouseDownAnywhere(event) {
        let menu = this.menu;
        let dropdown = this.dropdown;
        let mRect = menu.getBoundingClientRect();
        let dRect = dropdown == null ? null : dropdown.getBoundingClientRect();
        let x = event.x;
        let y = event.y;
        if (!this.props.disabled && x >= mRect.left && x <= mRect.right && y >= mRect.top && y <= mRect.bottom) {
            this.setState({
                extended: true,
                left: this.calculateLeft(mRect),
                top: this.calculateTop(mRect)
            });
        } else if (this.state.extended && !(x >= dRect.left && x <= dRect.right && y >= dRect.top && y <= dRect.bottom)) {
            this.setState({
                extended: false
            });
        }
    }

    calculateLeft(rect) {
        let anchor = this.props.anchor;
        if (anchor === undefined)
            anchor = "leftBottom";
        let offsetX = this.props.offsetX;
        if (offsetX === undefined)
            offsetX = 0;
        let result = (anchor.startsWith("left") ? rect.left : rect.right) + offsetX;
        return result;
    }

    calculateTop(rect) {
        let anchor = this.props.anchor;
        if (anchor === undefined)
            anchor = "leftBottom";
        let offsetY = this.props.offsetY;
        if (offsetY === undefined)
            offsetY = 0;
        let result = (anchor.endsWith("Top") ? rect.top : rect.bottom) + offsetY;
        return result;
    }

    render() {
        return (
            <React.Fragment>
                <button
                    onClick={() => this.menu.focus()}
                    onKeyDown={this.onKeyDown}
                    disabled={this.props.disabled}
                    ref={(ref) => this.menu = ref}
                    style={{
                        width: "200px"
                    }}
                    className={this.state.extended ? "main-menu-open" : "main-menu"}
                >
                    {this.props.buttonContent}
                </button>
                {this.state.extended && <div
                    ref={(ref) => this.dropdown = ref}
                    style={{
                        position: "absolute",
                        bottom: (document.documentElement.clientHeight - window.scrollY - this.state.top) + "px",
                        left: this.state.left + "px"
                    }}
                >
                    {this.props.children}
                </div>}
            </React.Fragment>
        );
    }
}

export default Menu;