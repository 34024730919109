import React from "react";

class TriggeredPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onResizeOrScroll = this.onResizeOrScroll.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        document.body.addEventListener('mousedown', this.onMouseDown, true);
        window.addEventListener('resize', this.onResizeOrScroll, true);
        window.addEventListener('scroll', this.onResizeOrScroll, false);
    }

    componentWillUnmount() {
        document.body.removeEventListener('mousedown', this.onMouseDown, true);
        window.removeEventListener('resize', this.onResizeOrScroll, true);
        window.removeEventListener('scroll', this.onResizeOrScroll, false);
    }

    onMouseDown(event) {
        let trigger = this.triggerRef;
        let popup = this.popupRef;
        let triggerRect = trigger.getBoundingClientRect();
        let popupRect = popup == null ? null : popup.getBoundingClientRect();
        let x = event.x;
        let y = event.y;
        if (!this.state.visible && x >= triggerRect.left && x <= triggerRect.right && y >= triggerRect.top && y <= triggerRect.bottom) {
            this.setState({visible: true});
            window.addEventListener("keydown", this.onKeyDown, this.props.captureKeyboard === true);
        } else if (this.state.visible && !(x >= popupRect.left && x <= popupRect.right && y >= popupRect.top && y <= popupRect.bottom)) {
            this.setState({visible: false});
            window.removeEventListener("keydown", this.onKeyDown, this.props.captureKeyboard === true);
        }
    }

    onResizeOrScroll() {
        this.setState({visible: false});
    }

    onKeyDown(event) {
        if (event.which === 27 || event.which === 13)
            this.setState({visible: false});
        if (this.props.onKeyDown)
            this.props.onKeyDown(event);
    }

    computePosition() {
        let triggerAnchor = this.props.triggerAnchor ? this.props.triggerAnchor : "leftBottom";
        let popupAnchor = this.props.popupAnchor ? this.props.popupAnchor : "leftTop";
        let offsetX = this.props.offsetX ? this.props.offsetX : 0;
        let offsetY = this.props.offsetY ? this.props.offsetY : 0;
        let rect = this.triggerRef.getBoundingClientRect();
        let x = (triggerAnchor.startsWith("left") ? rect.left : rect.right) + offsetX;
        let y = (triggerAnchor.endsWith("Top") ? rect.top : rect.bottom) + offsetY;
        let result = {
            position: "absolute",
        }
        if (popupAnchor.startsWith("left"))
            result.left = x + "px";
        else
            result.right = document.documentElement.clientWidth - window.scrollX - x;
        if (popupAnchor.endsWith("Top"))
            result.top = y + "px";
        else
            result.bottom = document.documentElement.clientHeight - window.scrollY - y;
        return result;
    }

    render() {
        let trigger = React.cloneElement(this.props.trigger, {
            ref: (ref) => {
                this.triggerRef = ref
            }
        });
        let popup = this.state.visible && this.triggerRef != null ?
            React.cloneElement(this.props.popup, {
                ref: (ref) => {
                    this.popupRef = ref
                },
                style: {
                    ...this.props.popup.props.style,
                    ...this.computePosition()
                }
            }) :
            "";
        return (
            <>
                {trigger}
                {this.state.visible && this.triggerRef != null && popup}
            </>
        );
    }
}

export default TriggeredPopup;