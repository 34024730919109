import React from 'react';
import './App.css';
import Calendar from "./Calendar";
import LocalDateTime from "./LocalDateTime";
import $ from "jquery";
import ContentEditable from "./ContentEditable";
import MainMenu from "./components/MainMenu";
import MenuItem from "./components/MenuItem";
import TriggeredPopup from "./popup/TriggeredPopup";
import Select from "./popup/Select";

class App extends React.Component {
    static regExpLine = /^([+✔]\s*)?(?:([<>.-])|([01]\d|2[0123]|\d)(?:[.|:]([012345]\d))?(?:\s*-\s*([01]\d|2[0123]|\d)(?:[.|:]([012345]\d))?)?)[\s]+([^\[]+)(?:\[(\d{0,5})([TMJtmj]) ([012]\d|3[01]|\d)\.(0\d|1[012]|\d)\.(\d{4}) ([012]\d|3[01]|\d)\.(0\d|1[012]|\d)\.(\d{4})])?$/;
    static regExpMonth = /^(12|11|10|\d)\/(\d{4})$/;

    constructor(props) {
        super(props);
        let today = LocalDateTime.today();
        let startMonth = today.addDays(1 - today.getDay());
        this.state = {
            username: "",
            password: "",
            loggedIn: true,
            loginFailed: false,
            scrollTop: 0,
            date: today,
            dataKey: LocalDateTime.now().toString(),
            data: "",
            modified: false,
            valid: true,
            conflict: false,
            history: [],
            historyIndex: 0,
            loading: false,
            saving: false,
            startMonth: startMonth,
            startMonthText: startMonth.toShortGermanMonthYearString(),
            timespanText: "13",
            timespan: 13,
            lastSynchronization: new LocalDateTime(2000, 1, 1),
            now: LocalDateTime.now()
        }

        this.cmdPressed = false;

        this.onLoginClicked = this.onLoginClicked.bind(this);
        this.onDateClicked = this.onDateClicked.bind(this);
        this.onReceiveData = this.onReceiveData.bind(this);
        this.onDataChanged = this.onDataChanged.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onChangeHistoryIndex = this.onChangeHistoryIndex.bind(this);
        this.onChangeTimespan = this.onChangeTimespan.bind(this);
        this.onChangeStartMonth = this.onChangeStartMonth.bind(this);
        this.keyDownOnTextField = this.keyDownOnTextField.bind(this);
        this.keyDownOnSelect = this.keyDownOnSelect.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.beforeUnload = this.beforeUnload.bind(this);
    }

    onLoginClicked() {
        this.ajax(
            "login",
            {username: this.state.username, password: this.state.password},
            (response) => {
                if (response.success) {
                    this.setState({username: "", password: "", loggedIn: true, loginFailed: false});
                    this.onDateClicked(this.state.date);
                } else
                    this.setState({username: "", password: "", loggedIn: false, loginFailed: true});
            },
            () => alert("Der Server antwortet nicht."));
    }

    keyDownOnTextField(event) {
        switch (event.keyCode) {
            case 37:
            case 38:
            case 39:
            case 40:
                event.stopPropagation();
                break;
            default:
        }
    }

    keyDownOnSelect(event) {
        event.stopPropagation();
        switch (event.keyCode) {
            case 38:
                if (this.state.historyIndex > 0)
                    this.onChangeHistoryIndex(this.state.historyIndex - 1);
                break;
            case 40:
                if (this.state.historyIndex < this.state.history.length - 1)
                    this.onChangeHistoryIndex(this.state.historyIndex + 1);
                break;
            default:
        }
    }

    getLastModification() {
        return this.state.history.length > 0 ?
            this.state.history[this.state.history.length - 1] :
            new LocalDateTime(1900, 1, 1);
    }

    beforeUnload(event) {
        if (this.state.modified) {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Older browsers supported custom message
            event.returnValue = 'Die Änderungen wurden noch nicht gespeichert. Möchtest du die Seite wirklich verlassen?';
        }
    }


    componentDidMount() {
        window.addEventListener('beforeunload', this.beforeUnload);
        window.addEventListener("keydown", this.onKeyDown);
        window.addEventListener("keyup", this.onKeyUp);
        this.onDateClicked(this.state.date);
        window.setInterval(() => this.setState({now: LocalDateTime.now()}), 1000);
        window.setInterval(() => {
            if (this.state.loggedIn)
                this.ajax('getLastModification', {date: this.state.date.toISOString()}, (response) => {
                    if (response.success) {
                        let lastModification = this.getLastModification();
                        if (!response.lastModification.equals(lastModification))
                            if (this.state.modified)
                                this.reportConflict();
                            else
                                this.onDateClicked(this.state.date);
                        else
                            this.setState({
                                lastSynchronization: LocalDateTime.now()
                            });
                    } else
                        this.setState({loggedIn: false});
                })
        }, 60000);
    }

    reportConflict() {
        if (!this.state.conflict)
            alert("Es wurde ein Änderungskonflikt erkannt!");
        this.setState({conflict: true});
    }


    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
        window.addEventListener("keyup", this.onKeyUp);
    }

    onKeyDown(event) {
        if (this.state.loggedIn) {
            switch (event.which) {
                case 91:
                case 224:
                    this.cmdPressed = true;
                    break;
                case 83:
                case 13:
                    if (this.cmdPressed) {
                        event.preventDefault();
                        event.stopPropagation();
                        if (this.state.modified && this.state.valid)
                            this.onSaveClicked();
                    }
                    break;
                case 27:
                    event.preventDefault();
                    event.stopPropagation();
                    if (this.state.modified)
                        this.onCancelClicked();
                    break;
                case 37:
                    if (this.cmdPressed) {
                        let target = document.getElementById("inputRangeStart");
                        target.value = this.state.startMonth.addMonths(-1).toShortGermanMonthYearString();
                        this.onChangeStartMonth({target})
                    } else
                        this.onDateClicked(this.state.date.addDays(-1));
                    event.stopPropagation();
                    event.preventDefault();
                    break;
                case 38:
                    if (this.cmdPressed) {
                        let target = document.getElementById("inputRangeTimespan");
                        target.value = (this.state.timespan + 1).toString();
                        this.onChangeTimespan({target})
                    } else
                        this.onDateClicked(this.state.date.addDays(-7));
                    event.stopPropagation();
                    event.preventDefault();
                    break;
                case 39:
                    if (this.cmdPressed) {
                        let target = document.getElementById("inputRangeStart");
                        target.value = this.state.startMonth.addMonths(1).toShortGermanMonthYearString();
                        this.onChangeStartMonth({target})
                    } else
                        this.onDateClicked(this.state.date.addDays(1));
                    event.stopPropagation();
                    event.preventDefault();
                    break;
                case 40:
                    if (this.cmdPressed) {
                        let target = document.getElementById("inputRangeTimespan");
                        target.value = (this.state.timespan - 1).toString();
                        this.onChangeTimespan({target})
                    } else
                        this.onDateClicked(this.state.date.addDays(7));
                    event.stopPropagation();
                    event.preventDefault();
                    break;
                default:
            }
        } else if (event.which === 13)
            this.onLoginClicked();
    }

    onKeyUp(event) {
        switch (event.which) {
            case 91:
            case 224:
                this.cmdPressed = false;
                break;
            default:
        }
    }

    parseLine(line) {
        let tokens = App.regExpLine.exec(line);
        let done = tokens[1] !== undefined;
        let timeless = tokens[2] !== undefined;
        let repeated = tokens[8] !== undefined;
        let text = tokens[7].trim();
        let year, month, day;
        if (repeated) {
            year = parseInt(tokens[12]);
            month = parseInt(tokens[11]);
            day = parseInt(tokens[10]);
        } else {
            year = this.state.date.getYear();
            month = this.state.date.getMonth();
            day = this.state.date.getDay();
        }
        let start, end;
        if (timeless) {
            if (repeated)
                start = new LocalDateTime(year, month, day, 0, 0);
            else
                start = new LocalDateTime(year, month, day, this.lastParsedDate.getHour(), this.lastParsedDate.getMinute());
            end = start;
        } else {
            let startHour = parseInt(tokens[3]);
            let startMinute = tokens[4] === undefined ? 0 : parseInt(tokens[4]);
            start = new LocalDateTime(year, month, day, startHour, startMinute);
            if (tokens[5] === undefined)
                end = start;
            else {
                let endHour = parseInt(tokens[5]);
                let endMinute = tokens[6] === undefined ? 0 : parseInt(tokens[6]);
                end = new LocalDateTime(year, month, day, endHour, endMinute);
            }
        }
        let intervalNumber, intervalUnit, intervalEnd;
        if (repeated) {
            intervalNumber = parseInt(tokens[8]);
            intervalUnit = tokens[9];
            let intervalEndDay = parseInt(tokens[13]);
            let intervalEndMonth = parseInt(tokens[14]);
            let intervalEndYear = parseInt(tokens[15]);
            intervalEnd = new LocalDateTime(intervalEndYear, intervalEndMonth, intervalEndDay);
        } else {
            intervalNumber = 0;
            intervalUnit = 'T';
            intervalEnd = start;
        }
        let sequence;
        if (repeated)
            sequence = -1;
        else {
            this.sequence = start.equals(this.lastParsedDate) ?
                this.sequence + 1 :
                0;
            sequence = this.sequence;
        }
        if (!timeless)
            this.lastParsedDate = start;
        return {
            timeless: timeless,
            start: start.toISOString(),
            end: end.toISOString(),
            text: text,
            sequence: sequence,
            interval_number: intervalNumber,
            interval_unit: intervalUnit.toUpperCase(),
            interval_end: intervalEnd.toISOString(),
            done: done
        };
    }

    onSaveClicked() {
        this.setState({saving: true});
        let lines = this.state.data.split(/[\n\u0085\u2028\u2029]|\r\n?/);
        this.lastParsedDate = this.state.date;
        this.sequence = 0;
        let lastEntry;
        let entries = lines
            .filter(line => line.trim() !== "")
            .map(line => {
                line = line.trim();
                if (lastEntry !== undefined && /^[<>.]/.test(line)) {
                    lastEntry.text = lastEntry.text + "\n" + line.substring(1).trim();
                    return null;
                } else {
                    lastEntry = this.parseLine(line.trim());
                    return lastEntry;
                }
            })
            .filter(line => line !== null);
        this.ajax("put", {
            date: this.state.date.toISOString(),
            entries: entries,
            lastModification: this.getLastModification().toISOString(),
            force: this.state.conflict
        }, (response) => {
            this.setState({saving: false});
            if (response.success)
                this.getData(this.state.date);
            else if (response.error === 'conflict')
                this.reportConflict();
            else
                this.setState({loggedIn: false});
        }, () => {
            this.setState({saving: false});
            alert("Der Server antwortet nicht!");
        });
    }

    onCancelClicked() {
        this.getData(this.state.date);
    }

    checkIfValid(data) {
        let lines = data.split(/[\n\u0085\u2028\u2029]|\r\n?/);
        return lines.every((line, index) => {
                line = line.trim();
                if (line === "")
                    return true;
                else if (index > 0 && /^[<>.]/.test(line))
                    return true;
                else {
                    let tokens = App.regExpLine.exec(line);
                    if (!tokens)
                        return false;
                    else if (tokens[8] === undefined)
                        return true;
                    else {
                        let intervalStart = new LocalDateTime(parseInt(tokens[12]), parseInt(tokens[11]), parseInt(tokens[10]));
                        let intervalEnd = new LocalDateTime(parseInt(tokens[15]), parseInt(tokens[14]), parseInt(tokens[13]));
                        return intervalStart.isValid() && intervalEnd.isValid();
                    }
                }
            }
        );
    }

    onDataChanged(event) {
        let data = event.target.value;
        this.setState({
            data: data,
            modified: true,
            valid: this.checkIfValid(data)
        });
    }

    onChangeStartMonth(event) {
        let text = event.target.value.trim();
        let tokens = App.regExpMonth.exec(text);
        if (tokens) {
            let month = parseInt(tokens[1]);
            let year = parseInt(tokens[2]);
            let startMonth = new LocalDateTime(year, month, 1);
            this.setState({startMonth: startMonth, startMonthText: event.target.value});
            event.target.style.backgroundColor = "#eeeeee";
        } else {
            this.setState({startMonthText: event.target.value});
            event.target.style.backgroundColor = "#ffdddd";
        }
    }

    onChangeTimespan(event) {
        let text = event.target.value.trim();
        if (/^\d+$/.test(text) && text !== "0") {
            this.setState({timespanText: text, timespan: parseInt(text)});
            event.target.style.backgroundColor = "#eeeeee";
        } else {
            this.setState({timespanText: text});
            event.target.style.backgroundColor = "#ffdddd";
        }
    }

    onChangeHistoryIndex(historyIndex) {
        let historyEntry = this.state.history[historyIndex];
        let date = this.state.date;
        this.ajax("getRevision",
            {date: date.toISOString(), revision: historyEntry.toISOString()},
            (response) => {
                if (response.success) {
                    let data = this.getDataForEntries(response.entries);
                    this.setState({
                        dataKey: LocalDateTime.now().toString(),
                        data: data,
                        historyIndex: historyIndex,
                        scrollTop: 0
                    });
                } else
                    this.setState({loggedIn: false});
            });
    }

    onDateClicked(date) {
        if (!this.state.modified) {
            this.setState({date: date, scrollTop: 0});
            this.getData(date);
        }
    }

    getData(date) {
        this.setState({
            loading: true,
            data: "... Daten werden geladen ..."
        });
        this.ajax("get", {date: date.toISOString()}, this.onReceiveData);
    }

    getDataForEntries(entries) {
        let os = require('os');
        let result = "";
        entries.forEach((entry, index) => {
                if (index > 0)
                    result += os.EOL;
                if (entry.done)
                    result += "\u{2714} ";
                if (entry.timeless)
                    result += "-";
                else if (entry.start.equals(entry.end))
                    result += entry.start.toShortGermanTimeString();
                else
                    result += entry.start.toShortGermanTimeString() + " - " + entry.end.toShortGermanTimeString();
                let intervalString = entry.interval_number > 0 ?
                    " [" + entry.interval_number + entry.interval_unit + " " + entry.start.toShortGermanDateString() + " " + entry.interval_end.toShortGermanDateString() + "]" :
                    "";
                let blankIndentation = entry.timeless ? "" : entry.start.equals(entry.end) ? "    " : "            ";
                if (entry.done)
                    blankIndentation += "  ";
                if (entry.text.includes("\n")) {
                    let text = entry.text;
                    text = text.replace(/\n/, intervalString + "\n");
                    text = text.replace(/\n/g, os.EOL + blankIndentation + "  > ");
                    result += " " + text;
                } else
                    result += " " + entry.text + intervalString;
            }
        );
        return result;
    }

    onReceiveData(response) {
        if (response.success) {
            let data = this.getDataForEntries(response.entries);
            let history = Object.values(response.history);
            this.setState({
                    dataKey: LocalDateTime.now().toString(),
                    data: data,
                    modified: false,
                    conflict: false,
                    history: history,
                    historyIndex: history.length - 1,
                    loading: false,
                    lastSynchronization: LocalDateTime.now()
                }
            );
        } else
            this.setState({loggedIn: false});
    }

    ajax(command, parameters, callbackSuccess, callbackFail = () => {
    }) {
        let request = $.ajax({
            url: "ajax.php",
            type: "post",
            data: {command: command, parameters: JSON.stringify(parameters)},
            dataType: "text",
            timeout: 5000
        });
        request.done(function (text) {
            console.log(text);
            let response = JSON.parse(text, App.dateParser);
            console.log(response);
            callbackSuccess(response, parameters);
        });
        request.fail(function (jqXHR, textStatus, errorThrown) {
            console.log("Ajax request failed with textStatus '" + textStatus + "' and errorThrown '" + errorThrown + "'");
            callbackFail(textStatus, errorThrown);
        });
    }

    static dateParser(key, value) {
        if (typeof value === 'string' && LocalDateTime.regExpIso.exec(value))
            return LocalDateTime.parseIso(value);
        else
            return value;
    }

    render() {
        let backgroundColor = this.state.loading ?
            "#eeeeee" :
            this.state.modified ?
                this.state.valid ?
                    "#eeffee" :
                    "#ffeeee" :
                "white";
        let textColor = this.state.loading ?
            "#8800ff" :
            "black";
        let history = this.state.history;
        let historyIndex = this.state.historyIndex;
        let textFieldDisabled = this.state.saving || this.state.loading || (history.length > 0 && historyIndex !== history.length - 1);
        let specialDayName = this.state.date.getSpecialDayName();
        let saveButtonStyle = this.state.conflict ? {color: "red", backgroundColor: "grey"} : {}
        if (this.state.loggedIn)
            return (
                <div className="app">
                    <div className="calendar-container">
                        <h1>Kalender</h1>
                        <div className="status calendar-status">
                            {this.state.now.toShortGermanDateString()} {this.state.now.toGermanTimeString()} Uhr
                        </div>
                        <Calendar
                            startMonth={this.state.startMonth}
                            endMonth={this.state.startMonth.addMonths(this.state.timespan - 1)}
                            onDateClicked={this.onDateClicked}
                            highlightedDate={this.state.date}
                        />
                        <div className="range">
                            <input
                                className="timespan"
                                id={"inputRangeTimespan"}
                                type="text"
                                value={this.state.timespanText}
                                onChange={this.onChangeTimespan}
                                onKeyDown={this.keyDownOnTextField}
                                tabIndex="3"
                            />
                            Monate ab
                            <input
                                className="startmonth"
                                id={"inputRangeStart"}
                                type="text"
                                value={this.state.startMonthText}
                                onChange={this.onChangeStartMonth}
                                onKeyDown={this.keyDownOnTextField}
                                tabIndex="4"
                            />
                        </div>
                    </div>
                    <div className="text-field-container">
                        <div className="text-field-heading">
                            {this.state.date.toLongGermanDateString()}
                            {specialDayName ? " (" + specialDayName + ")" : ""}
                        </div>
                        <div className="status text-field-status">
                            {this.state.conflict && <span style={{color: "red"}}>Änderungskonflikt erkannt! </span>}
                            Letzte Synchronisierung am {this.state.lastSynchronization.toShortGermanDateString()} um {
                            this.state.lastSynchronization.toGermanTimeString()}
                        </div>
                        <ContentEditable
                            scrollTop={this.state.scrollTop}
                            onScroll={(event) => this.setState({scrollTop: event.target.scrollTop})}
                            key={this.state.dataKey}
                            className="text-field"
                            style={{backgroundColor: backgroundColor, color: textColor}}
                            value={this.state.data}
                            onChange={this.onDataChanged}
                            disabled={textFieldDisabled}
                            onKeyDown={this.keyDownOnTextField}
                            tabIndex="1"
                        />
                        <div className="text-field-footer">
                            <div>
                                <button
                                    onClick={this.onSaveClicked}
                                    disabled={!this.state.valid || !this.state.modified || this.state.saving}
                                    style={saveButtonStyle}
                                >
                                    Speichern
                                </button>
                                <button
                                    onClick={this.onCancelClicked}
                                    disabled={!this.state.modified || this.state.saving}
                                    style={{marginLeft: "10px"}}
                                >
                                    Abbrechen
                                </button>
                            </div>
                            <Select
                                getText={entry => entry.datetime.toGermanDateTimeString()}
                                options={this.state.history.map((datetime, index) => {
                                    return {index: index, datetime: datetime}
                                })}
                                selected={
                                    this.state.history.length === 0 ?
                                        null :
                                        {
                                            index: this.state.historyIndex,
                                            datetime: this.state.history[this.state.historyIndex]
                                        }
                                }
                                onChange={(option) => this.onChangeHistoryIndex(option.index)}
                            />
                            <button
                                style={{width: "150px"}}
                                onClick={() => {
                                    this.ajax(
                                        "logout",
                                        {},
                                        () => this.setState({loggedIn: false}),
                                        () => alert("Der Server antwortet nicht. Logout konnte nicht ausgeführt werden."));
                                }}
                            >
                                Abmelden
                            </button>
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <div className="app-login" onKeyDown={this.onKeyDown}>
                    <h1 style={{
                        gridColumn: "span 2",
                        lineHeight: "40px",
                        height: "45px",
                        borderBottom: "1px solid grey",
                        padding: "10px 10px 0 20px",
                        margin: "-20px -20px 10px -20px",
                        backgroundColor: "lightblue",
                        fontSize: "30px",
                        textAlign: "left"
                    }}>Kalender</h1>
                    <p className="login">Benutzer:</p>
                    <input className="login"
                           style={{backgroundColor: this.state.loginFailed ? "#ffeeee" : "white"}}
                           type="text"
                           value={this.state.username}
                           onChange={(event) => this.setState({username: event.target.value})}/>
                    <p className="login">Passwort:</p>
                    <input className="login"
                           style={{backgroundColor: this.state.loginFailed ? "#ffeeee" : "white"}}
                           type="password"
                           value={this.state.password}
                           onChange={(event) => this.setState({password: event.target.value})}/>
                    <div style={{
                        textAlign: "right",
                        gridColumn: "span 2"
                    }}>
                        <button
                            style={{marginBottom: "0", width: "100%", border: "1px solid grey"}}
                            onClick={this.onLoginClicked}>
                            Anmelden
                        </button>
                    </div>
                </div>
            );
    }
}

export default App;
