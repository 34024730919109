import React from "react";

class ItemMenuDropdown extends React.Component {
    render() {
        return (
            <div
                onScroll={(event) => event.stopPropagation()}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#eeeeee",
                    border: "1px solid grey",
                    borderRadius: "10px",
                    maxHeight: "50vh",
                    overflow: "auto"
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default ItemMenuDropdown;
