import React from "react";

class ContentEditable extends React.Component {
    componentDidMount() {
        this.ref.innerHTML = "<pre>" + this.props.value + "</pre>";
        this.ref.scrollTop = this.props.scrollTop;
    }

    render() {
        return (
            <div
                onScroll={this.props.onScroll}
                ref={(ref) => this.ref = ref}
                contentEditable={!this.props.disabled}
                className={this.props.className}
                style={this.props.style}
                onKeyDown={this.props.onKeyDown}
                tabIndex={this.props.tabIndex}
                onInput={(event) => this.props.onChange({target: {value: event.currentTarget.innerText}})}
            />
        );
    }
}

export default ContentEditable;